import { useRouter } from "next/router"
import React, { useContext, useEffect } from "react"

// commons | components
import { ConsumerLayout } from "../components"
import { InitialLoader } from "@project/common"

// contexts
import { AuthContext } from "../contexts/AuthProvider"

const WithRestrictedRoute = (AuthComponent) => {
  return function RestrictedComponent(props) {
    const { authenticated, loading } = useContext(AuthContext)

    const router = useRouter()
    const pathname: any = router?.query?.from || "/"
    useEffect(() => {
      if (authenticated) {
        router.replace(pathname)
      }
    }, [authenticated, loading])

    if (loading) {
      return (
        <InitialLoader
          logoImg={
            <img
              src={"/assets/imgs/logo-md.png"}
              height={60}
              width={60}
              alt={"logo"}
            />
          }
        />
      )
    }
    return (
      <>
        {!authenticated && (
          <ConsumerLayout>
            <AuthComponent {...props} />
          </ConsumerLayout>
        )}
      </>
    )
  }
}

export default WithRestrictedRoute
