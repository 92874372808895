import React from "react"

// packages
import { t } from "i18next"
import * as Sentry from "@sentry/node"
import { signInWithEmailAndPassword } from "firebase/auth"

// commons | components
import { ConsumerLogin } from "../../containers"
import { auth, catchExceptionCallback } from "@project/common"

// HOC
import WithRestrictedRoute from "../../hoc/withRestrictedRoute"
import { PageHead } from "../../components/PageHead"

function Login(): JSX.Element {
  const [loading, setLoading] = React.useState(false)
  const userSuffix = process.env.NEXT_PUBLIC_USER_SUFFIX
  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true)
    try {
      auth.tenantId = process.env.NEXT_PUBLIC_ADULT_TENANT_ID
      let submittedEmail = values.email
      const pattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
      if (!values.email.match(pattern)) {
        submittedEmail = values.email + userSuffix
      }
      await signInWithEmailAndPassword(auth, submittedEmail, values?.password)
    } catch (error) {
      let message = error
      switch (error.code) {
        case "auth/wrong-password":
          message = "Email address or password does not match"
          break
        case "auth/user-not-found":
          message = "User doesnot exist"
          break
        case "auth/user-disabled":
          message =
            "We could not login you at this moment. Please contact your administration for inquiry"
          break
        case "auth/too-many-requests":
          message = t("Too many attempts to login.")
          break
        case "auth/invalid-email":
          message = t("Enter valid email.")
          break
        default:
          message = "Something went wrong. Please contact administrator"
          break
      }
      catchExceptionCallback(t(message))
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <PageHead>{t("My page login")}</PageHead>
      <ConsumerLogin handleSubmit={handleSubmit} loading={loading} />
    </>
  )
}

export default WithRestrictedRoute(Login)
